import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({ data }) => (
  <Layout location="appointments">
    <SEO title="Appointments" />
    <div className="mx-auto prose lg:prose-lg divide-y divide-gray-200">
      <article>
        <h1>Appointments</h1>
        <p>Appointments are 50 minutes. Longer appointments of up to 90 minutes may be required for working with trauma and we would discuss this in advance.</p>
        <p>Depending on the complexity of difficulty, the initial appointments (at least one appointment but approximately 4-6 appointments) are largely focused on assessment and formulation. A psychological formulation is an understanding of what your difficulties are, what keeps them going and why these difficulties have arisen. This is a useful way to consider your specific therapeutic goals and how we might choose to work on these.</p>
        <p>Most often, during assessment, we will also begin to introduce some therapeutic work - for example some tools for working with some aspects of what feels difficult. Later appointments focus on therapy, but assessment is always ongoing because talking in a deeper way can develop new understanding.  The number of appointments depends on your difficulty and therapeutic goals and we will discuss this. This can also be reviewed, if needed.</p>
        <h2 id="therapeutic-space" className="nomargin">Therapeutic Space</h2>
        <Img className="mx-auto mt-0"
          fluid={data.file.childImageSharp.fluid}
          alt="Photo of the therapeutic space"
        />
        <p>I am based in Lancaster and appointments take place at a clinic in Garstang, which is calm, private, has parking and is accessible. Please note that due to COVID-19 I am currently offering online appointments only.</p>
        <iframe title="Google Map for therapeutic space location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2351.0580936021515!2d-2.771981383474442!3d53.89517138009778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b656dfbe0d28b%3A0xae0829f971eb6483!2sVitality%20Garstang!5e0!3m2!1sen!2suk!4v1597605694130!5m2!1sen!2suk" width="100%" height="450" frameborder="0" style={{border: 0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        <h2 id="confidentiality">Confidentiality</h2>
        <p>I take your privacy seriously. I am registered with the Information Commissioner's Office (ICO), store your data securely and all work remains confidential.</p>
        <p>Please see our <Link to="/privacy/" target="_blank">privacy policy</Link> for more information.</p>
        <h2 id="fees">Fees</h2>
        <p>For self-funded therapy, current fees are £100 for a 50 minute appointment.  Please note that Insurance cases have a different funding structure. I currently accept payments via direct banking.</p>
        <h2 id="cancellations">Cancellations</h2>
        <p>Please give at least 48 hours notice if you need to cancel or reschedule an appointment. The full fee is payable if you do not provide adequate notice.</p>
      </article>
      <div className="pt-6 pb-16">
        <p>
          If you want to talk to me in more detail about the services I offer then please <Link to="/contact/">contact me</Link>.
        </p>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "Room.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default AboutPage
